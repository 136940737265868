// src/components/layout/Layout.jsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const Layout = () => {
  return (
    <div className="min-h-screen bg-[#252526] text-[#D4D4D4] flex flex-col font-mono">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;