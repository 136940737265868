import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full py-2 sm:py-3 text-center text-[#6A9955] text-xs sm:text-sm border-t border-[#252526] bg-[#1E1E1E] mt-auto">
      <div className="px-4 truncate">
        maincode@australia:~/melbourne$ echo "© 2024"
      </div>
    </footer>
  );
};

export default Footer;