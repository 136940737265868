export const Button = ({ 
    children, 
    variant = 'default', 
    className = '', 
    onClick 
  }) => {
    const baseStyles = "px-4 py-2 rounded-md transition-colors duration-200 w-full sm:w-auto text-center";
    
    const variantStyles = {
      eng: "bg-[#007ACC] text-white hover:bg-[#005F9E] shadow-md hover:shadow-lg",
      default: "bg-[#4EC9B0] text-[#1E1E1E] hover:bg-[#4EC9B0]/90 shadow-md hover:shadow-lg",
      outline: "border-2 border-[#6A9955] text-[#6A9955] hover:bg-[#6A9955] hover:text-[#1E1E1E]"
    };
    
    return (
      <button
        className={`${baseStyles} ${variantStyles[variant]} ${className}`}
        onClick={onClick}
      >
        {children}
      </button>
    );
  };