import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className="bg-[#1E1E1E]">
      <header className="w-full py-4 px-4 sm:px-6 flex justify-between items-center border-b border-[#333333]">
        <Link to="/" className="flex-shrink-0">
          <img
            src="/logo.svg"
            alt="Maincode Logo"
            className="h-8 w-auto"
          />
        </Link>

        <Link
          to="/about-us"
          className="text-[#CE9178] hover:text-[#F5F5F5] transition-colors ml-auto"
        >
          About Us
        </Link>
      </header>
    </div>
  );
};

export default Header;