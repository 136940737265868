import { Button } from '../components/ui/Button';

const HomePage = () => {
  return (
    <main className="flex-grow container mx-auto px-6 sm:px-4 flex flex-col justify-center bg-[#252526]">
      <section className="max-w-3xl w-full mx-auto">
        <div className="text-[#6A9955] text-lg mb-4">
          {"// Our Mission:"}
        </div>

        <h1 className="text-3xl sm:text-5xl font-bold mb-8 tracking-tight leading-tight">
          <span className="text-[#569CD6]">#define</span>
          <span className="text-[#9CDCFE]"> EXCELLENCE</span>
        </h1>
        
        <p className="text-base sm:text-xl mb-12 text-[#CE9178]">
          Join the company created for engineers, run by engineers, building the next generation of groundbreaking products
        </p>

        <Button 
          variant="eng"
          onClick={() => window.open('https://app.dover.com/jobs/maincode', '_blank')}
          className="text-lg py-3"
        >
          View Open Positions →
        </Button>
      </section>
    </main>
  );
};

export default HomePage;