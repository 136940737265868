import React from 'react';
import { Button } from '../components/ui/Button';

const AboutPage = () => {
    return (
        <main className="flex-grow container mx-auto px-4 py-16 sm:py-24 bg-[#252526]">
            <section className="max-w-3xl w-full">
                <h1 className="text-2xl sm:text-4xl font-bold mb-8 tracking-tight leading-tight">
                    <span className="text-[#569CD6]">class</span>
                    <span className="text-[#9CDCFE]"> Maincode</span>
                    <span className="text-[#D4D4D4]"> {"{"}</span>
                </h1>

                {/* Mission Section */}
                <div className="mb-8 sm:mb-12">
                    <div className="text-[#6A9955] text-sm sm:text-base mb-2">{"// About Us"}</div>
                    <p className="text-[#CE9178] text-sm sm:text-base pl-4">
                        At Maincode, we're on a mission to restore the heart and soul of engineering. We're creating a place where engineers own their work, their culture, and their code, where passion, grit, and craftsmanship come together to build extraordinary products. We reject bureaucracy, embrace hard problems, and believe greatness is earned.
                    </p>
                </div>

                {/* Values Section */}
                <div className="mb-8 sm:mb-12">
                    <div className="text-[#6A9955] text-sm sm:text-base mb-2">{"// Our Culture"}</div>
                    <p className="text-[#CE9178] text-sm sm:text-base pl-4">
                        Our culture is built around bringing together humble, curious, and passionate engineers and giving them the support, tools, and environment to develop incredible skills and products. Engineering excellence is at the core of our DNA, along with a commitment to continuous learning and growth. We focus on relentless performance and uncompromising standards. Bureaucracy, red tape, and bar-lowering thinking are refactored out to allow hardcore engineering to thrive and push the boundaries of what's possible. Here, engineers can truly be engineers, tackling hard problems and building extraordinary things together.
                    </p>
                </div>

                {/* Join Us Section */}
                <div className="mb-8 sm:mb-12">
                    <div className="text-[#6A9955] text-sm sm:text-base mb-2">{"// Join Our Team"}</div>

                    <Button
                        variant="eng"
                        onClick={() => window.open('https://app.dover.com/jobs/maincode', '_blank')}
                        className="text-base sm:text-lg py-2 sm:py-3"
                    >
                        View Open Positions →
                    </Button>
                </div>

                <div className="text-[#D4D4D4] text-2xl sm:text-4xl">{"}"}</div>
            </section>
        </main>
    );
};

export default AboutPage;